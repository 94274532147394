import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2021_12_09_STI-advances-POCT.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

  return (
  <StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">On December 9, 2021, NACCHO, the National Coalition of STD Directors (NCSD), 
					and the National Association of State and Territorial AIDS Directors (NASTAD) hosted a webinar on a new point-of-care technology 
					for testing of sexually transmitted infections (STIs) and the impact of its use for health departments.
					</p>
					<p data-aos="fade-up">Historically, STI testing has required that samples be sent to a lab for testing while the patient awaits 
					results and possible treatment. A fast diagnosis and appropriate treatment are essential in preventing the spread of STIs. 
					A point-of-care test allows for faster diagnosis and eliminates the 4–7-day turnaround. A reliable, low-cost, point-of-care 
					test which allows clinicians and healthcare workers to diagnose and provide accurate treatment in one visit could help reduce 
					burden on the patient and help improve health outcomes including by helping to reduce the threat of antibiotic resistance 
					from empirical treatment. By streamlining the process of providing accurate treatment and counseling at once, providers can save t
					ime and money, reduce patient anxiety, and minimize the loss of patients to follow-up care (treatment).
					</p>
					<p data-aos="fade-up">In August, Visby Medical received FDA clearance and a CLIA waiver for its point-of-care PCR test for the 
					detection of chlamydia, gonorrhea, and trichomonas with a vaginal swab. Results are available within 30 minutes and can be shared 
					within a single patient visit. This is the first point-of-care test that has received this approval.
					</p>

					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Rising Rates of STIs</li>
						<li>Traditional STI Testing Methods and Challenges</li>
						<li>Benefits Presented by Point-of-Care Testing</li>
						<li>Impact on Health Department Services and Care with Point-of-Care Testing.</li>
						<li>Q&A from attendees</li>
					</ul>
				  </div>
				</div>
			
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/gary-schoolnik.jpg"
					width={200}
					alt="Dr. Gary Schoolnik"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Gary Schoolnik is Chief Medical Officer, Visby Medical. He is also Professor of Medicine (emeritus), Stanford Medical School, 
				Attending Physician in Internal Medicine and Infectious Diseases at Stanford University Hospital and Associate 
				Director of Stanford’s Institute for Immunology, Transplantation and Infection.</p>

				<p data-aos="fade-up">Dr. Schoolnik received his M.D. degree and infectious diseases subspecialty training at the University of Washington in Seattle, served 
				as medical intern, resident and chief resident at Harvard’s Massachusetts General Hospital in Boston and he was a research associate 
				and associate physician at the Rockefeller University in Manhattan. While on the Stanford Medical School faculty he served as Chief 
				of the Division of Infectious Diseases and Geographic Medicine. National service included his appointment to the 
				Advisory Council of the National Institutes of Allergy and Infectious Diseases of the NIH and his role as chair of the 
				NIH Trans-Institute Committee on the Human Microbiome and co-chair of the NIH Blue Ribbon Committee on Bioterrorism. 
				International consultative activities include the Board of Scientific Counselors of Fudan University in Shanghai. He was founding 
				editor of the journal Molecular Microbiology and Associate Editor of the Journal of Clinical Investigation. </p>

				<p data-aos="fade-up">His academic research focuses on the molecular, genetic and genomic aspects of infectious agents and on the 
				development of new drugs, vaccines and diagnostics. Gary has 6 issued patents.</p>

				<p data-aos="fade-up">Full lists of publications: visit <a href="https://profiles.stanford.edu/gary-schoolnik" target="_blank" rel="noopener noreferrer">https://profiles.stanford.edu/gary-schoolnik</a></p>
				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
    />
  )
}

export default FullBody
