import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import FormLanding from "./../screens/webinar-replay/2021-12-09-schoolnik/form-landing"
import BodyLanding from "./../screens/webinar-replay/2021-12-09-schoolnik/body-landing"

const Schoolnik2 = () => {
  return (
    <React.Fragment>
      <FormLanding />
      <BodyLanding pageName="display" localStorageID="2021-12-09-schoolnik-webinar-replay" />
    </React.Fragment>
  )
}
export default Schoolnik2

export const Head = () => (
  <Seo
        title="Webinar Replay: STI Testing: New Point-of-Care Advances"
        description="This webinar covers a new point-of-care technology for testing of sexually transmitted infections (STIs) and the impact of its use for health departments."
        image="/meta/webinar-schoolnik-2021-12-09.jpg"
        recaptcha
/>
)
