import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Container from "components/container"
import WebinarForm from "components/webinar/form"
import WebinarPresenter from "components/webinar/presenter"
import WebinarContainer from "components/webinar/form-container"

const FORM_ID = process.env.GATSBY_FORM_WEBINAR_2021_12_09_SCHOOLNIK

const FormFull = props => {

  return (
    <WebinarContainer
			viewAllowed={props.viewAllowed}
    >
		<WebinarPresenter>
			<Container data-aos="fade-up">
			<React.Fragment>
			<WebinarForm
				videoDisplayTitle="STI Testing: New Point-of-Care Advances"
				localStorageID="2021-12-09-schoolnik-webinar-replay"
				pageSlug="/webinar-replay/2021-12-09-schoolnik-view/"
				wordpressFormID={FORM_ID}
			/>
			</React.Fragment>	
				<div className="right__column">
					<h3>Presenter</h3>
					<div className="columnPresenter">
					<StaticImage
						src="../../../../assets/images/webinar-replay/gary-schoolnik.jpg"
						width={200}
						alt="Dr. Gary Schoolnik"
						className="presenter__image"
					/>
					<p><span className="title">Gary Schoolnik, MD</span><br />Chief Medical Officer,<br />Visby Medical</p>
					</div>
				</div>
			</Container>
		</WebinarPresenter>
    </WebinarContainer>
  )
}

export default FormFull
